import { fork, all, call, put } from 'redux-saga/effects';
import { checkTokenExpiration, checkRefreshTokenExpiration } from '../utils/token';
import api from '../utils/api';

import {
  watchGetToken,
  watchLoginUser,
  watchLoginSuccess,
  watchLogout,
  watchRefreshToken,
  watchRefreshTokenSuccess,
  watchRefreshTokenFailure,
  handleInvalidToken,
  watchGetTokenFailure,
  watchSetUserRole,
  watchRequestPasswordResetRequest,
  watchResetPasswordRequest,
  watchResetPasswordSuccess,
  watchGetFileViaTokenRequest,
} from './Auth';

import {
  watchGetProductsRequest,
  watchGetProductRequest,
  watchCreateProductRequest,
  watchCreateProductSuccess,
  watchUpdateProductRequest,
  watchUpdateProductSuccess,
  watchDeleteProductRequest,
  watchDeleteProductSuccess,
  watchGetProductConstantsRequest,
  watchGetFilteredProductsRequest,
  watchSetProductCategoriesRequest,
  watchSendProductsIdsToDownloadPdfRequest,
  watchSendProductsIdsToDownloadPdfSuccess,
} from './Product';

import {
  watchGetUsersRequest,
  watchGetUserRequest,
  watchUpdateUserRequest,
  watchDeleteUserRequest,
  watchGetUserResultsRequest,
  watchDeleteUsersRequest,
  watchUpdateUserPasswordSuccess,
  watchUpdateUserPasswordRequest,
  watchGetUserWithGroupsRequest,
  watchGetUserProductsRequest,
  watchCreateUserRequest,
} from './User';

import { watchCreateSurveyRequest, watchDeleteSurveyRequest, watchDeleteSurveySuccess, watchGetSurveyRequest, watchGetSurveysRequest, watchUpdateSurveyRequest, watchCreateSurveySuccess, watchUpdateSurveySuccess, watchSendDirectSurveyRequest, watchSendDirectSurveySuccess } from './Survey';

import {
  watchCreateAttributeRequest,
  watchDeleteAttributeRequest,
  watchGetAttributeRequest,
  watchGetAttributesRequest,
  watchUpdateAttributeRequest,
  watchCreateAttributeSuccess,
  watchDeleteAttributeSuccess,
  watchUpdateAttributeSuccess,
  watchAddAttributeToUserRequest,
  watchAddAttributeToUserSuccess,
  watchAddAttributesToUserRequest,
  watchAddAttributesToUserSuccess,
  watchDeleteAttributeToUserRequest,
  watchDeleteAttributeToUserSuccess,
  watchGetUserAttributes,
  watchGetUserAttributeHistory,
} from './Attributes';

import { rootWatcherChartsRequest } from './Charts';

import { rootWatcherAppointments } from './Appointments';

import { rootWatcherCube } from './Cube';

import { rootWatcherHealthProfile } from './HealthProfile';

import { rootWatcherHealthAssistant } from './HealthAssistant';

import { rootWatcherAuthors } from './Authors';

import { rootWatcherFilter } from './Filters';

import { rootWatcherCubeGroups } from './CubeGroups';

import {
  watchGetDocumentsRequest,
  watchGetDocumentRequest,
  watchCreateDocumentRequest,
  watchUpdateDocumentRequest,
  watchDeleteDocumentRequest,
  watchCreateDocumentSuccess,
  watchUpdateDocumentSuccess,
  watchGetDocumentByVersionRequest,
  watchCreateDocumentPermissionsRequest,
  watchDeleteDocumentPermissionsRequest,
  watchGetDocumentPermissionsRequest,
} from './Documents';

import {
  watchGetNewsListRequest,
  watchGetNewsRequest,
  watchUpdateNewsRequest,
  watchUpdateNewsSuccess,
  watchDeleteNewsRequest,
  watchDeleteNewsSuccess,
  watchCreateNewsRequest,
  watchCreateNewsSuccess,
  watchDeleteNewsListRequest,
  watchGetNewsByVersionRequest,
  watchDeleteNewsListSuccess,
  watchCopyNewsRequest,
  watchCopyNewsSuccess,
  watchSendDirectPushRequest,
  watchSendDirectPushSuccess,
  watchGetUserFeedRequest,
  watchApproveNewsRequest,
  watchGetUserWorkflowRequest,
  watchPickupNewsRequest,
  watchRejectNewsRequest,
  watchGetWorkflowListRequest,
  watchUpdateLatestVersionRequest,
  watchPickupNewsSuccess,
  watchRejectNewsSuccess,
  watchApproveNewsSuccess,
  watchCreateNewVersionRequest,
  watchGetWorkflowHistoryRequest,
  watchCreateNewVersionSuccess,
  watchSetNewsCategoriesRequest,
  watchAddGroupsToNewsSuccess,
  watchAddGroupsToNewsRequest,
  watchCancelPublicationRequest,
  watchCancelPublicationSuccess,
  watchSetPublicationDateRequest,
  watchSetNewsCategoriesSuccess,
  watchSetPublicationDateSuccess,
  watchUpdateLatestVersionSuccess,
  watchUpdateNewsPartiallyRequest,
  watchUpdateNewsPartiallySuccess,
  watchSetNewsTagsRequest,
  watchSetNewsTagsSuccess,
  watchGetNewsTemplatesRequest,
  watchSendDirectPushTemplateRequest,
  watchSendDirectPushTemplateSuccess,
  watchSendNewsIdsToDownloadPdfRequest,
  watchSendNewsIdsToDownloadPdfSuccess,
  watchApproveNewsAllStepsRequest,
  watchGetNewsListAllRequest,
  watchDeleteUserFeedItemsRequest,
} from './News';

import { watchGetCategoriesRequest, watchCreateCategoryRequest, watchDeleteCategoryRequest, watchCreateCategorySuccess, watchUpdateCategorySuccess, watchGetCategoryRequest, watchUpdateCategoryRequest, watchDeleteCategorySuccess } from './Categories';

import {
  watchGetTagsRequest,
  watchCreateTagRequest,
  watchDeleteTagRequest,
  watchCreateTagSuccess,
  watchUpdateTagSuccess,
  watchGetTagRequest,
  watchUpdateTagRequest,
  watchDeleteTagSuccess,
  watchAddTagToUserRequest,
  watchAddTagToUserSuccess,
  watchDeleteTagFromUserRequest,
  watchDeleteTagFromUserSuccess,
} from './Tags';

import { watchGetTenantsRequest, watchGetTenantRequest, watchCreateTenantRequest, watchCreateTenantSuccess, watchUpdateTenant, watchUpdateTenantSuccess } from './Tenants';

import {
  watchGetCampaignsRequest,
  watchGetCampaignRequest,
  watchGetCampaignByVersionRequest,
  watchCreateCampaignRequest,
  watchCreateCampaignSuccess,
  watchUpdateCampaign,
  watchUpdateCampaignSuccess,
  watchStartCampaignRequest,
  watchGetUserCampaignsRequest,
  watchGetUserArchivedCampaignsRequest,
  watchStartCampaignSuccess,
  watchStopUserCampaignRequest,
  watchGetArchivedCampaignRequest,
  watchGetActiveCampaignRequest,
  watchStartCampaignAcceletateRequest,
  watchStartCampaignAcceletateSuccess,
  watchCheckConsistencyRequest,
  watchGetAllUserActiveCampaignsRequest,
  watchSetCampaignMilestoneRequest,
} from './Campaigns';

import {
  watchGetGroupsRequest,
  watchGetGroupRequest,
  watchCreateGroupRequest,
  watchUpdateGroupRequest,
  watchDeleteGroupRequest,
  watchCreateGroupSuccess,
  watchUpdateGroupSuccess,
  watchDeleteGroupSuccess,
  watchAddUsersToGroupRequest,
  watchAddUsersToGroupSuccess,
  watchDeleteUsersFromGroupRequest,
  watchDeleteUsersFromGroupSuccess,
  watchGetGroupUsersRequest,
} from './Groups';

import { watchGetComments, watchCreateComment, watchUpdateComment, watchDeleteComment, watchAddCommentSuccess, watchUpdateCommentSuccess, watchDeleteCommentSuccess } from './Comments';

import { watchGetCalendarEntriesRequest, watchGetCalendarEntriesSuccess, watchCreateCalendarEntryRequest, watchUpdateCalendarEntryRequest, watchDeleteCalendarEntryRequest, watchUpdateCalendarEntrySuccess, watchCreateCalendarEntrySuccess, watchDeleteCalendarEntrySuccess } from './Calendar';

import { watchGetCalendarICalEntriesRequest, watchGetCalendarICalEntriesSuccess } from './CalendarICal';
import { watchGetActivityCsvRequest, watchGetAllAndUnreadFeedItemsCountRequest, watchGetAllHealthAssistantsRequest, watchGetAllUserCubesForIdRequest, watchGetAllUserCubesRequest, watchGetAllUsersRequest, watchGetKeycloakUserEventsRequest } from './Activity';
import { watchDeleteCodeRequest, watchEditCodeRequest, watchGenerateCodesRequest, watchGetAllCodesRequest, watchGetCodeRequest } from './Codes';
// resuable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
export function* apiRequest(entity, apiFn, action) {
  const { id, data } = action;
  const { response, error } = id ? yield call(apiFn, id, data) : yield call(apiFn, data);
  if (!error) yield put(entity.success(response));
  else yield put(entity.failure(error));
}

export function* secureApiRequest(entity, apiFn, action) {
  let token = checkTokenExpiration();

  if (!token || token === 'expired') {
    if (token !== 'expired') yield put({ type: 'LOGOUT_REQUEST' });
    else {
      const refreshToken = checkRefreshTokenExpiration();
      if (!refreshToken || refreshToken === 'expired') {
        yield call(handleInvalidToken);
      } else {
        const { response, error } = yield call(api.refreshToken, refreshToken);
        if (error) yield call(handleInvalidToken);
        token = response.access_token;

        window.localStorage.setItem('IQ_TOKEN', token);
        window.localStorage.setItem('IQ_REFRESH_TOKEN', response.refresh_token);
      }
    }
  }

  if (!token || token === 'expired') return;
  const { id, data } = action;

  const { response, error } = id ? (data ? yield call(apiFn, id, data, token) : yield call(apiFn, id, token)) : data ? yield call(apiFn, data, token) : yield call(apiFn, token);
  if (!error) yield put(entity.success(response));
  else yield put(entity.failure(error));
}

export default function* root() {
  yield all([
    fork(watchGetToken),
    fork(watchLoginUser),
    fork(watchLoginSuccess),
    fork(watchLogout),
    fork(watchRefreshToken),
    fork(watchRefreshTokenSuccess),
    fork(watchGetTokenFailure),
    fork(watchRefreshTokenFailure),
    fork(watchGetFileViaTokenRequest),
    fork(watchGetUsersRequest),
    fork(watchGetUserRequest),
    fork(watchUpdateUserRequest),
    fork(watchDeleteUserRequest),
    fork(watchSetUserRole),
    fork(watchGetUserResultsRequest),
    fork(watchRequestPasswordResetRequest),
    fork(watchResetPasswordRequest),
    fork(watchResetPasswordSuccess),
    fork(watchDeleteUsersRequest),
    fork(watchGetDocumentsRequest),
    fork(watchGetDocumentRequest),
    fork(watchCreateDocumentRequest),
    fork(watchUpdateDocumentRequest),
    fork(watchDeleteDocumentRequest),
    fork(watchCreateDocumentSuccess),
    fork(watchUpdateDocumentSuccess),
    fork(watchGetDocumentByVersionRequest),
    fork(watchGetNewsListRequest),
    fork(watchGetNewsRequest),
    fork(watchDeleteNewsRequest),
    fork(watchDeleteNewsSuccess),
    fork(watchCreateNewsRequest),
    fork(watchUpdateNewsRequest),
    fork(watchUpdateNewsSuccess),
    fork(watchDeleteNewsListRequest),
    fork(watchDeleteNewsListSuccess),
    fork(watchGetNewsByVersionRequest),
    fork(watchCreateNewsSuccess),
    fork(watchGetProductsRequest),
    fork(watchGetProductRequest),
    fork(watchCreateProductRequest),
    fork(watchCreateProductSuccess),
    fork(watchUpdateProductRequest),
    fork(watchUpdateProductSuccess),
    fork(watchDeleteProductRequest),
    fork(watchDeleteProductSuccess),
    fork(watchGetGroupsRequest),
    fork(watchGetGroupRequest),
    fork(watchCreateGroupRequest),
    fork(watchUpdateGroupRequest),
    fork(watchDeleteGroupRequest),
    fork(watchCreateGroupSuccess),
    fork(watchUpdateGroupSuccess),
    fork(watchDeleteGroupSuccess),
    fork(watchGetProductConstantsRequest),
    fork(watchGetFilteredProductsRequest),
    fork(watchGetCalendarEntriesRequest),
    fork(watchCreateCalendarEntryRequest),
    fork(watchUpdateCalendarEntryRequest),
    fork(watchDeleteCalendarEntryRequest),
    fork(watchDeleteCalendarEntrySuccess),
    fork(watchUpdateCalendarEntrySuccess),
    fork(watchCreateCalendarEntrySuccess),
    fork(watchGetCalendarEntriesSuccess),
    fork(watchGetCalendarICalEntriesRequest),
    fork(watchGetCalendarICalEntriesSuccess),
    fork(watchCopyNewsSuccess),
    fork(watchCopyNewsRequest),
    fork(watchUpdateUserPasswordRequest),
    fork(watchUpdateUserPasswordSuccess),
    fork(watchGetUserWithGroupsRequest),
    fork(watchDeleteUsersFromGroupSuccess),
    fork(watchGetGroupUsersRequest),
    fork(watchDeleteUsersFromGroupRequest),
    fork(watchAddUsersToGroupSuccess),
    fork(watchAddUsersToGroupRequest),
    fork(watchDeleteDocumentPermissionsRequest),
    fork(watchGetDocumentPermissionsRequest),
    fork(watchCreateDocumentPermissionsRequest),
    fork(watchSendDirectPushRequest),
    fork(watchSendDirectPushSuccess),
    fork(watchPickupNewsSuccess),
    fork(watchGetUserFeedRequest),
    fork(watchGetUserProductsRequest),
    fork(watchRejectNewsRequest),
    fork(watchUpdateLatestVersionRequest),
    fork(watchPickupNewsRequest),
    fork(watchGetUserWorkflowRequest),
    fork(watchApproveNewsRequest),
    fork(watchApproveNewsAllStepsRequest),
    fork(watchGetWorkflowListRequest),
    fork(watchRejectNewsSuccess),
    fork(watchApproveNewsSuccess),
    fork(watchCreateNewVersionRequest),
    fork(watchGetWorkflowHistoryRequest),
    fork(watchGetComments),
    fork(watchCreateComment),
    fork(watchUpdateComment),
    fork(watchDeleteComment),
    fork(watchAddCommentSuccess),
    fork(watchUpdateCommentSuccess),
    fork(watchDeleteCommentSuccess),
    fork(watchCreateNewVersionSuccess),
    fork(watchSetNewsCategoriesRequest),
    fork(watchSetProductCategoriesRequest),
    fork(watchGetCategoriesRequest),
    fork(watchCreateCategoryRequest),
    fork(watchDeleteCategoryRequest),
    fork(watchCreateCategorySuccess),
    fork(watchUpdateCategorySuccess),
    fork(watchSetNewsCategoriesSuccess),
    fork(watchGetCategoryRequest),
    fork(watchUpdateCategoryRequest),
    fork(watchDeleteCategorySuccess),
    fork(watchAddGroupsToNewsRequest),
    fork(watchAddGroupsToNewsSuccess),
    fork(watchSetPublicationDateRequest),
    fork(watchSetPublicationDateSuccess),
    fork(watchCancelPublicationSuccess),
    fork(watchUpdateLatestVersionSuccess),
    fork(watchCancelPublicationRequest),
    fork(watchUpdateNewsPartiallyRequest),
    fork(watchUpdateNewsPartiallySuccess),
    fork(watchGetSurveysRequest),
    fork(watchGetSurveyRequest),
    fork(watchUpdateSurveyRequest),
    fork(watchDeleteSurveyRequest),
    fork(watchCreateSurveyRequest),
    fork(watchGetTenantsRequest),
    fork(watchGetTenantRequest),
    fork(watchUpdateTenant),
    fork(watchUpdateTenantSuccess),
    fork(watchCreateTenantRequest),
    fork(watchCreateTenantSuccess),
    fork(watchGetCampaignsRequest),
    fork(watchGetCampaignRequest),
    fork(watchGetCampaignByVersionRequest),
    fork(watchStartCampaignSuccess),
    fork(watchStartCampaignRequest),
    fork(watchUpdateCampaign),
    fork(watchUpdateCampaignSuccess),
    fork(watchCreateCampaignRequest),
    fork(watchCreateCampaignSuccess),
    fork(watchGetTagsRequest),
    fork(watchCreateTagRequest),
    fork(watchDeleteTagRequest),
    fork(watchCreateTagSuccess),
    fork(watchUpdateTagSuccess),
    fork(watchGetTagRequest),
    fork(watchUpdateTagRequest),
    fork(watchDeleteTagSuccess),
    fork(watchCreateUserRequest),
    fork(watchDeleteTagFromUserRequest),
    fork(watchDeleteTagFromUserSuccess),
    fork(watchAddTagToUserSuccess),
    fork(watchAddTagToUserRequest),
    fork(watchSetNewsTagsRequest),
    fork(watchSetNewsTagsSuccess),
    fork(watchCreateAttributeRequest),
    fork(watchDeleteAttributeRequest),
    fork(watchGetAttributeRequest),
    fork(watchGetAttributesRequest),
    fork(watchUpdateAttributeRequest),
    fork(watchCreateAttributeSuccess),
    fork(watchDeleteAttributeSuccess),
    fork(watchUpdateAttributeSuccess),
    fork(watchAddAttributeToUserRequest),
    fork(watchAddAttributeToUserSuccess),
    fork(watchAddAttributesToUserRequest),
    fork(watchAddAttributesToUserSuccess),
    fork(watchDeleteAttributeToUserRequest),
    fork(watchDeleteAttributeToUserSuccess),
    fork(watchGetUserAttributes),
    fork(watchGetUserArchivedCampaignsRequest),
    fork(watchGetUserAttributeHistory),
    fork(watchCreateSurveySuccess),
    fork(watchUpdateSurveySuccess),
    fork(watchGetUserCampaignsRequest),
    fork(watchSendDirectSurveyRequest),
    fork(watchSendDirectSurveySuccess),
    fork(watchDeleteSurveySuccess),
    fork(watchGetNewsTemplatesRequest),
    fork(watchSendDirectPushTemplateRequest),
    fork(watchSendDirectPushTemplateSuccess),
    fork(watchSendNewsIdsToDownloadPdfRequest),
    fork(watchSendNewsIdsToDownloadPdfSuccess),
    fork(watchSendProductsIdsToDownloadPdfRequest),
    fork(watchSendProductsIdsToDownloadPdfSuccess),
    fork(rootWatcherChartsRequest),
    fork(rootWatcherAppointments),
    fork(rootWatcherCube),
    fork(rootWatcherHealthProfile),
    fork(rootWatcherHealthAssistant),
    fork(watchStopUserCampaignRequest),
    fork(watchGetArchivedCampaignRequest),
    fork(watchGetActiveCampaignRequest),
    fork(watchStartCampaignAcceletateRequest),
    fork(watchStartCampaignAcceletateSuccess),
    fork(watchSetCampaignMilestoneRequest),
    fork(watchGetNewsListAllRequest),
    fork(watchCheckConsistencyRequest),
    fork(watchGetAllUserActiveCampaignsRequest),
    fork(rootWatcherAuthors),
    fork(rootWatcherFilter),
    fork(rootWatcherCubeGroups),
    fork(watchGetKeycloakUserEventsRequest),
    fork(watchGetAllAndUnreadFeedItemsCountRequest),
    fork(watchGetAllUsersRequest),
    fork(watchGetAllUserCubesRequest),
    fork(watchGetAllHealthAssistantsRequest),
    fork(watchGetAllUserCubesForIdRequest),
    fork(watchDeleteUserFeedItemsRequest),
    fork(watchGetActivityCsvRequest),
    fork(watchGetAllCodesRequest),
    fork(watchGenerateCodesRequest),
    fork(watchDeleteCodeRequest),
    fork(watchGetCodeRequest),
    fork(watchEditCodeRequest),
  ]);
}
